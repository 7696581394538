@import (less) '../../../../../node_modules/react-image-gallery/styles/css/image-gallery.css';

.image-gallery {
  .image-gallery-swipe {
    .image-gallery-slides {
      .image-gallery-image {
        min-height: 500px;
      }
    }
  }

  .image-gallery-icon {
    filter: none;
    -webkit-filter: none;
  }
  .image-gallery-left-nav {
    font-size: 2.824rem;
    svg {
      display: none;
    }
    &:before {
      font-family: @font-icon;
      content: '\FB0D';
      text-shadow: none;
      color: @red;
    }
  }
  .image-gallery-right-nav {
    font-size: 2.824rem;
    svg {
      display: none;
    }
    &:before {
      font-family: @font-icon;
      content: '\FB0F';
      color: @red;
      text-shadow: none;
      line-height: 36px;
    }
  }

  .image-gallery-left-nav:hover::before,
  .image-gallery-right-nav:hover::before {
    color: @grey;
  }

  .image-gallery-bullet {
    &:hover {
      background: @grey;
    }
    &.active {
      background: @red;
    }
  }
}

.flag-wrapper {
  width: 30px;
  margin: auto;
  cursor: pointer;
}
