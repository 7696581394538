@font-face {
  font-family: 'champagne';
  src: url('./fonts/champagne_limousines.ttf');
}

@font-face {
  font-family: 'Material Design Icons';
  src: url('./fonts/materialdesignicons-webfont.eot?v=4.9.95');
  src: url('./fonts/materialdesignicons-webfont.eot?#iefix&v=4.9.95') format('embedded-opentype'),
    url('./fonts/materialdesignicons-webfont.woff2?v=4.9.95') format('woff2'),
    url('./fonts/materialdesignicons-webfont.woff?v=4.9.95') format('woff'),
    url('./fonts/materialdesignicons-webfont.ttf?v=4.9.95') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@basic-font: 'Myriad Variable Concept', sans-serif;
@font-icon: 'Material Design Icons';
@text-color: rgba(0, 0, 0, 0.85);
@font-size-base: 1rem; //17px
@font-size-page-title: 4rem; //112px
@font-size-page-title-lg-xl: 3.2rem; //112px
@font-size-page-title-lg: 3rem; //112px
@font-size-page-title-sm: 2rem; //112px
@font-size-page-title-xs: 1.588rem; //27px
@font-size-lg-xxl: 2.471rem; //42px
@font-size-lg-xl: 2.118rem; //36px
@font-size-lg-sm: 1.824rem; //31px
@font-size-lg-xs: 1.588rem; //27px
@font-size-lg-xxs: 1.294rem; //22px
@font-size-lg: 1.118rem; //19px
@font-size-sm: 0.8824rem; //15px
@font-size-xs: 0.7647rem; //13px
@font-size-xxs: 0.6471rem; //11px

@font-weight-light: 300;
@font-weight-base: 400;
@font-weight-semi-bold: 600;
@font-weight-bold: 700;
@font-weight-extra-bold: 900;

html {
  font-size: @font-size-base;
}

h1 {
  @media screen and (max-width: @breakpoint-xl) and (min-width: @breakpoint-md) {
    font-size: @font-size-page-title-lg;
  }
  @media screen and (max-width: calc(@breakpoint-md - 1px)) {
    font-size: @font-size-page-title-sm;
  }
  font-family: 'champagne';
  font-weight: @font-weight-semi-bold;
  font-size: @font-size-page-title;
  margin: 0;
  letter-spacing: 10px;
}
h2 {
  @media screen and (max-width: @breakpoint-lg) {
    font-size: @font-size-page-title-xs;
  }
  font-family: @basic-font;
  font-size: @font-size-page-title-sm;
  font-weight: @font-weight-bold;
  line-height: 45px;
  letter-spacing: 5px;
  margin: 0;
}
h3 {
  @media screen and (max-width: @breakpoint-md) {
    font-size: @font-size-page-title-sm;
  }
  @media screen and (max-width: @breakpoint-sm) {
    font-size: @font-size-page-title-xs;
    letter-spacing: 3px;
  }
  font-size: @font-size-page-title-lg-xl;
  line-height: 39px;
  color: @white;
  margin: 0;
  letter-spacing: 10px;
}
h4 {
  font-size: @font-size-lg-xs;
  font-weight: @font-weight-semi-bold;
}
h5 {
  font-size: @font-size-page-title-sm;
  font-weight: @font-weight-base;
}
