.menu {
  &-title {
    font-size: @font-size-lg;
    color: @white;
  }
  &-dropdown {
    &-wrapper {
      cursor: pointer;
    }
    width: 200px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    &-user {
      font-weight: 500;
      color: rgba(0, 0, 0, 0.5);
      cursor: default !important;
      &:hover {
        font-weight: 500;
        color: rgba(0, 0, 0, 0.5);
      }
    }
    &-logout {
      cursor: pointer;
      display: flex;
      align-items: center;
      &:hover {
        background: @white;
        color: @red;
      }
    }
  }
}

.ant-menu-item {
  width: 10%;
  text-align: center;
  &-active,
  &-selected {
    > a {
      color: @red !important;
    }

    color: @red !important;
    border-bottom: 2px solid @red !important;
  }
}
