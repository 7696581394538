body {
  overflow-x: hidden;
  height: 100%;
  width: 100%;
}
ul {
  padding: 0;
}
img {
  width: 100%;
}
.page-content {
  padding: 0 200px 50px 200px;
  font-size: 1.118rem;
  font-family: 'Myriad Variable Concept', sans-serif;
  color: rgba(0, 0, 0, 0.85);
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 576px) {
  .page-content {
    padding: 0 25px 50px 25px;
  }
}
@media screen and (max-width: 1200px) and (min-width: calc(576px + 1px)) {
  .page-content {
    padding: 0 50px 50px 50px;
  }
}
.page-content section {
  padding: 1.333rem 0;
  width: 100%;
}
div.hoverable {
  margin: -15px 0;
  padding-top: 15px;
  padding-bottom: 15px;
  color: #251e20;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  cursor: pointer;
}
div.hoverable:hover {
  -webkit-transform: translateX(8px);
  transform: translateX(8px);
}
@font-face {
  font-family: 'champagne';
  src: url('../base/fonts/champagne_limousines.ttf');
}
@font-face {
  font-family: 'Material Design Icons';
  src: url('../base/fonts/materialdesignicons-webfont.eot?v=4.9.95');
  src: url('../base/fonts/materialdesignicons-webfont.eot?#iefix&v=4.9.95') format('embedded-opentype'), url('../base/fonts/materialdesignicons-webfont.woff2?v=4.9.95') format('woff2'), url('../base/fonts/materialdesignicons-webfont.woff?v=4.9.95') format('woff'), url('../base/fonts/materialdesignicons-webfont.ttf?v=4.9.95') format('truetype');
  font-weight: normal;
  font-style: normal;
}
html {
  font-size: 1rem;
}
h1 {
  font-family: 'champagne';
  font-weight: 600;
  font-size: 4rem;
  margin: 0;
  letter-spacing: 10px;
}
@media screen and (max-width: 1200px) and (min-width: 768px) {
  h1 {
    font-size: 3rem;
  }
}
@media screen and (max-width: calc(768px - 1px)) {
  h1 {
    font-size: 2rem;
  }
}
h2 {
  font-family: 'Myriad Variable Concept', sans-serif;
  font-size: 2rem;
  font-weight: 700;
  line-height: 45px;
  letter-spacing: 5px;
  margin: 0;
}
@media screen and (max-width: 992px) {
  h2 {
    font-size: 1.588rem;
  }
}
h3 {
  font-size: 3.2rem;
  line-height: 39px;
  color: #fff;
  margin: 0;
  letter-spacing: 10px;
}
@media screen and (max-width: 768px) {
  h3 {
    font-size: 2rem;
  }
}
@media screen and (max-width: 576px) {
  h3 {
    font-size: 1.588rem;
    letter-spacing: 3px;
  }
}
h4 {
  font-size: 1.588rem;
  font-weight: 600;
}
h5 {
  font-size: 2rem;
  font-weight: 400;
}
.auth {
  height: 100vh;
  background-image: url('../images/img/backgrounds/background.jpg');
  background-size: cover;
}
.auth-wrapper {
  width: 500px;
  height: max-content;
  padding: 20px;
  border: 2px solid #251e20;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.9);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.contact-descriptions-link {
  color: #251e20;
}
.contact-descriptions-link:hover {
  color: #5c1411;
}
.image-gallery-icon {
  color: #fff;
  transition: all 0.2s ease-out;
  appearance: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  outline: none;
  position: absolute;
  z-index: 4;
  filter: drop-shadow(0 2px 2px #1a1a1a);
}
@media (min-width: 768px) {
  .image-gallery-icon:hover {
    color: #337ab7;
  }
  .image-gallery-icon:hover .image-gallery-svg {
    transform: scale(1.1);
  }
}
.image-gallery-icon:focus {
  outline: 2px solid #337ab7;
}
.image-gallery-using-mouse .image-gallery-icon:focus {
  outline: none;
}
.image-gallery-fullscreen-button,
.image-gallery-play-button {
  bottom: 0;
  padding: 20px;
}
.image-gallery-fullscreen-button .image-gallery-svg,
.image-gallery-play-button .image-gallery-svg {
  height: 36px;
  width: 36px;
}
@media (max-width: 768px) {
  .image-gallery-fullscreen-button,
  .image-gallery-play-button {
    padding: 15px;
  }
  .image-gallery-fullscreen-button .image-gallery-svg,
  .image-gallery-play-button .image-gallery-svg {
    height: 24px;
    width: 24px;
  }
}
@media (max-width: 480px) {
  .image-gallery-fullscreen-button,
  .image-gallery-play-button {
    padding: 10px;
  }
  .image-gallery-fullscreen-button .image-gallery-svg,
  .image-gallery-play-button .image-gallery-svg {
    height: 16px;
    width: 16px;
  }
}
.image-gallery-fullscreen-button {
  right: 0;
}
.image-gallery-play-button {
  left: 0;
}
.image-gallery-left-nav,
.image-gallery-right-nav {
  padding: 50px 10px;
  top: 50%;
  transform: translateY(-50%);
}
.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 120px;
  width: 60px;
}
@media (max-width: 768px) {
  .image-gallery-left-nav .image-gallery-svg,
  .image-gallery-right-nav .image-gallery-svg {
    height: 72px;
    width: 36px;
  }
}
@media (max-width: 480px) {
  .image-gallery-left-nav .image-gallery-svg,
  .image-gallery-right-nav .image-gallery-svg {
    height: 48px;
    width: 24px;
  }
}
.image-gallery-left-nav[disabled],
.image-gallery-right-nav[disabled] {
  cursor: disabled;
  opacity: .6;
  pointer-events: none;
}
.image-gallery-left-nav {
  left: 0;
}
.image-gallery-right-nav {
  right: 0;
}
.image-gallery {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  position: relative;
}
.image-gallery.fullscreen-modal {
  background: #000;
  bottom: 0;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 5;
}
.image-gallery.fullscreen-modal .image-gallery-content {
  top: 50%;
  transform: translateY(-50%);
}
.image-gallery-content {
  position: relative;
  line-height: 0;
  top: 0;
}
.image-gallery-content.fullscreen {
  background: #000;
}
.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: calc(20vh);
}
.image-gallery-content.left .image-gallery-slide .image-gallery-image,
.image-gallery-content.right .image-gallery-slide .image-gallery-image {
  max-height: 100vh;
}
.image-gallery-slide-wrapper {
  position: relative;
}
.image-gallery-slide-wrapper.left,
.image-gallery-slide-wrapper.right {
  display: inline-block;
  width: calc(-10%);
}
@media (max-width: 768px) {
  .image-gallery-slide-wrapper.left,
  .image-gallery-slide-wrapper.right {
    width: calc(13%);
  }
}
.image-gallery-slide-wrapper.image-gallery-rtl {
  direction: rtl;
}
.image-gallery-slides {
  line-height: 0;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  text-align: center;
}
.image-gallery-slide {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.image-gallery-slide.center {
  position: relative;
}
.image-gallery-slide .image-gallery-image {
  width: 100%;
  object-fit: contain;
}
.image-gallery-slide .image-gallery-description {
  background: rgba(0, 0, 0, 0.4);
  bottom: 70px;
  color: #fff;
  left: 0;
  line-height: 1;
  padding: 10px 20px;
  position: absolute;
  white-space: normal;
}
@media (max-width: 768px) {
  .image-gallery-slide .image-gallery-description {
    bottom: 45px;
    font-size: .8em;
    padding: 8px 15px;
  }
}
.image-gallery-bullets {
  bottom: 20px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  width: 80%;
  z-index: 4;
}
.image-gallery-bullets .image-gallery-bullets-container {
  margin: 0;
  padding: 0;
  text-align: center;
}
.image-gallery-bullets .image-gallery-bullet {
  appearance: none;
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 50%;
  box-shadow: 0 1px 0 #1a1a1a;
  cursor: pointer;
  display: inline-block;
  margin: 0 5px;
  outline: none;
  padding: 5px;
  transition: background 0.2s ease-out;
}
@media (max-width: 768px) {
  .image-gallery-bullets .image-gallery-bullet {
    margin: 0 3px;
    padding: 3px;
  }
}
@media (max-width: 480px) {
  .image-gallery-bullets .image-gallery-bullet {
    padding: 2.7px;
  }
}
.image-gallery-bullets .image-gallery-bullet:focus,
.image-gallery-bullets .image-gallery-bullet:hover {
  background: #337ab7;
  transform: scale(1.1);
}
.image-gallery-bullets .image-gallery-bullet.active {
  background: #fff;
}
.image-gallery-thumbnails-wrapper {
  position: relative;
}
.image-gallery-thumbnails-wrapper.thumbnails-wrapper-rtl {
  direction: rtl;
}
.image-gallery-thumbnails-wrapper.left,
.image-gallery-thumbnails-wrapper.right {
  display: inline-block;
  vertical-align: top;
  width: 100px;
}
@media (max-width: 768px) {
  .image-gallery-thumbnails-wrapper.left,
  .image-gallery-thumbnails-wrapper.right {
    width: 81px;
  }
}
.image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails,
.image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails {
  height: 100%;
  width: 100%;
  left: 0;
  padding: 0;
  position: absolute;
  top: 0;
}
.image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails .image-gallery-thumbnail,
.image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails .image-gallery-thumbnail {
  display: block;
  margin-right: 0;
  padding: 0;
}
.image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails .image-gallery-thumbnail + .image-gallery-thumbnail,
.image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails .image-gallery-thumbnail + .image-gallery-thumbnail {
  margin-left: 0;
  margin-top: 2px;
}
.image-gallery-thumbnails-wrapper.left,
.image-gallery-thumbnails-wrapper.right {
  margin: 0 5px;
}
@media (max-width: 768px) {
  .image-gallery-thumbnails-wrapper.left,
  .image-gallery-thumbnails-wrapper.right {
    margin: 0 3px;
  }
}
.image-gallery-thumbnails {
  overflow: hidden;
  padding: 5px 0;
}
@media (max-width: 768px) {
  .image-gallery-thumbnails {
    padding: 3px 0;
  }
}
.image-gallery-thumbnails .image-gallery-thumbnails-container {
  cursor: pointer;
  text-align: center;
  transition: transform 0.45s ease-out;
  white-space: nowrap;
}
.image-gallery-thumbnail {
  display: inline-block;
  border: 4px solid transparent;
  transition: border 0.3s ease-out;
  width: 100px;
  background: transparent;
  padding: 0;
}
@media (max-width: 768px) {
  .image-gallery-thumbnail {
    border: 3px solid transparent;
    width: 81px;
  }
}
.image-gallery-thumbnail + .image-gallery-thumbnail {
  margin-left: 2px;
}
.image-gallery-thumbnail .image-gallery-thumbnail-inner {
  position: relative;
}
.image-gallery-thumbnail .image-gallery-thumbnail-image {
  vertical-align: middle;
  width: 100%;
  line-height: 0;
}
.image-gallery-thumbnail.active,
.image-gallery-thumbnail:hover,
.image-gallery-thumbnail:focus {
  outline: none;
  border: 4px solid #337ab7;
}
@media (max-width: 768px) {
  .image-gallery-thumbnail.active,
  .image-gallery-thumbnail:hover,
  .image-gallery-thumbnail:focus {
    border: 3px solid #337ab7;
  }
}
.image-gallery-thumbnail-label {
  box-sizing: border-box;
  color: white;
  font-size: 1em;
  left: 0;
  line-height: 1em;
  padding: 5%;
  position: absolute;
  top: 50%;
  text-shadow: 1px 1px 0 black;
  transform: translateY(-50%);
  white-space: normal;
  width: 100%;
}
@media (max-width: 768px) {
  .image-gallery-thumbnail-label {
    font-size: .8em;
    line-height: .8em;
  }
}
.image-gallery-index {
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
  line-height: 1;
  padding: 10px 20px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 4;
}
@media (max-width: 768px) {
  .image-gallery-index {
    font-size: .8em;
    padding: 5px 10px;
  }
}
.image-gallery .image-gallery-swipe .image-gallery-slides .image-gallery-image {
  min-height: 500px;
}
.image-gallery .image-gallery-icon {
  filter: none;
  -webkit-filter: none;
}
.image-gallery .image-gallery-left-nav {
  font-size: 2.824rem;
}
.image-gallery .image-gallery-left-nav svg {
  display: none;
}
.image-gallery .image-gallery-left-nav:before {
  font-family: 'Material Design Icons';
  content: '\FB0D';
  text-shadow: none;
  color: #5c1411;
}
.image-gallery .image-gallery-right-nav {
  font-size: 2.824rem;
}
.image-gallery .image-gallery-right-nav svg {
  display: none;
}
.image-gallery .image-gallery-right-nav:before {
  font-family: 'Material Design Icons';
  content: '\FB0F';
  color: #5c1411;
  text-shadow: none;
  line-height: 36px;
}
.image-gallery .image-gallery-left-nav:hover::before,
.image-gallery .image-gallery-right-nav:hover::before {
  color: #251e20;
}
.image-gallery .image-gallery-bullet:hover {
  background: #251e20;
}
.image-gallery .image-gallery-bullet.active {
  background: #5c1411;
}
.flag-wrapper {
  width: 30px;
  margin: auto;
  cursor: pointer;
}
.menu-title {
  font-size: 1.118rem;
  color: #fff;
}
.menu-dropdown {
  width: 200px;
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.menu-dropdown-wrapper {
  cursor: pointer;
}
.menu-dropdown-user {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
  cursor: default !important;
}
.menu-dropdown-user:hover {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
}
.menu-dropdown-logout {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.menu-dropdown-logout:hover {
  background: #fff;
  color: #5c1411;
}
.ant-menu-item {
  width: 10%;
  text-align: center;
}
.ant-menu-item-active,
.ant-menu-item-selected {
  color: #5c1411 !important;
  border-bottom: 2px solid #5c1411 !important;
}
.ant-menu-item-active > a,
.ant-menu-item-selected > a {
  color: #5c1411 !important;
}
.page-title-container {
  margin-top: 40px;
  z-index: 1;
}
@keyframes page-title-animation {
  from {
    height: 0px;
  }
  to {
    height: 100%;
  }
}
.page-title-container * > h1 {
  background: #f5f5f5;
}
.page-title-wrapper {
  z-index: 0;
  position: relative;
  height: 200px;
}
@media screen and (max-width: 768px) {
  .page-title-wrapper {
    height: 150px;
  }
}
@media screen and (max-width: 576px) {
  .page-title-wrapper {
    height: 120px;
  }
}
.page-title-rop {
  height: 100%;
  position: absolute;
  border-left: 4px solid rgba(0, 0, 0, 0.85);
  z-index: -1;
  animation: page-title-animation 1s;
}
.page-title-rop-left {
  left: 44%;
}
.page-title-rop-center-left {
  left: 48%;
}
.page-title-rop-center-right {
  left: 52%;
}
.page-title-rop-right {
  left: 56%;
}
.page-title-light-container {
  margin-top: 40px;
  z-index: 1;
}
@keyframes page-title-animation {
  from {
    height: 0px;
  }
  to {
    height: 100%;
  }
}
.page-title-light-container * > h1 {
  background: #251e20;
}
.page-title-light-wrapper {
  z-index: 0;
  position: relative;
  height: 200px;
}
@media screen and (max-width: 768px) {
  .page-title-light-wrapper {
    height: 150px;
  }
}
@media screen and (max-width: 576px) {
  .page-title-light-wrapper {
    height: 120px;
  }
}
.page-title-light-rop {
  height: 100%;
  position: absolute;
  border-left: 4px solid rgba(0, 0, 0, 0.85);
  z-index: -1;
  animation: page-title-animation 1s;
}
.page-title-light-rop-left {
  left: 44%;
}
.page-title-light-rop-center-left {
  left: 48%;
}
.page-title-light-rop-center-right {
  left: 52%;
}
.page-title-light-rop-right {
  left: 56%;
}
.page-title-dark-container {
  margin-top: 40px;
  z-index: 1;
}
@keyframes page-title-animation {
  from {
    height: 0px;
  }
  to {
    height: 100%;
  }
}
.page-title-dark-container * > h1 {
  color: #fff;
  background: #251e20;
}
.page-title-dark-wrapper {
  z-index: 0;
  position: relative;
  height: 200px;
}
@media screen and (max-width: 768px) {
  .page-title-dark-wrapper {
    height: 150px;
  }
}
@media screen and (max-width: 576px) {
  .page-title-dark-wrapper {
    height: 120px;
  }
}
.page-title-dark-rop {
  height: 100%;
  position: absolute;
  border-left: 4px solid #fff;
  z-index: -1;
  animation: page-title-animation 1s;
}
.page-title-dark-rop-left {
  left: 44%;
}
.page-title-dark-rop-center-left {
  left: 48%;
}
.page-title-dark-rop-center-right {
  left: 52%;
}
.page-title-dark-rop-right {
  left: 56%;
}
