// -------- General -----------
body {
  overflow-x: hidden;
  height: 100%;
  width: 100%;
}
ul {
  padding: 0;
}
img {
  width: 100%;
}
// -------- Colors -----------
@white: #fff;
@grey: #251e20;
@red: #5c1411;
@light-grey: #d4d4d4;
@background-color-1: #f5f5f5;
@background-color-2: #251e20;
@background-color-3: #d1d1d1;

// -------- Scaffolding Variables -----------
@border-radius-base: 6px;
@border-radius-sm: 3px;
@border-radius-md: 9px;
@border-radius-lg: 12px;
@border-radius-extralg: 24px;

// -------- Breakpoints -----------
@breakpoint-sm: 576px;
@breakpoint-md: 768px;
@breakpoint-lg: 992px;
@breakpoint-xl: 1200px;
@breakpoint-xxl: 1600px;

.page-content {
  @media screen and (max-width: @breakpoint-sm) {
    padding: 0 25px 50px 25px;
  }
  @media screen and (max-width: @breakpoint-xl) and (min-width: calc(@breakpoint-sm + 1px)) {
    padding: 0 50px 50px 50px;
  }

  padding: 0 200px 50px 200px;
  font-size: @font-size-lg;
  font-family: @basic-font;
  color: @text-color;
  display: flex;
  flex-direction: column;

  section {
    padding: 1.333rem 0;
    width: 100%;
  }
}

div.hoverable {
  margin: -15px 0;
  padding-top: 15px;
  padding-bottom: 15px;
  color: @grey;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  cursor: pointer;
  &:hover {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }
}
