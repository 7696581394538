.contact {
  &-descriptions {
    &-link {
      color: @grey;
      &:hover {
        color: @red;
      }
    }
  }
}
