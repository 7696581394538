.page-title {
  &-container {
    @keyframes page-title-animation {
      from {
        height: 0px;
      }
      to {
        height: 100%;
      }
    }
    margin-top: 40px;
    z-index: 1;
    * > h1 {
      background: @background-color-1;
    }
  }
  &-wrapper {
    z-index: 0;
    position: relative;
    @media screen and (max-width: @breakpoint-md) {
      height: 150px;
    }
    @media screen and (max-width: @breakpoint-sm) {
      height: 120px;
    }
    height: 200px;
  }
  &-rop {
    height: 100%;
    position: absolute;
    border-left: 4px solid @text-color;
    z-index: -1;
    animation: page-title-animation 1s;
    &-left {
      left: 44%;
    }
    &-center-left {
      left: 48%;
    }
    &-center-right {
      left: 52%;
    }
    &-right {
      left: 56%;
    }
  }
}

.page-title-light {
  &-container {
    @keyframes page-title-animation {
      from {
        height: 0px;
      }
      to {
        height: 100%;
      }
    }
    margin-top: 40px;
    z-index: 1;
    * > h1 {
      background: @background-color-2;
    }
  }
  &-wrapper {
    z-index: 0;
    position: relative;
    @media screen and (max-width: @breakpoint-md) {
      height: 150px;
    }
    @media screen and (max-width: @breakpoint-sm) {
      height: 120px;
    }
    height: 200px;
  }
  &-rop {
    height: 100%;
    position: absolute;
    border-left: 4px solid @text-color;
    z-index: -1;
    animation: page-title-animation 1s;
    &-left {
      left: 44%;
    }
    &-center-left {
      left: 48%;
    }
    &-center-right {
      left: 52%;
    }
    &-right {
      left: 56%;
    }
  }
}

.page-title-dark {
  &-container {
    @keyframes page-title-animation {
      from {
        height: 0px;
      }
      to {
        height: 100%;
      }
    }
    margin-top: 40px;
    z-index: 1;
    * > h1 {
      color: @white;
      background: @background-color-2;
    }
  }
  &-wrapper {
    z-index: 0;
    position: relative;
    @media screen and (max-width: @breakpoint-md) {
      height: 150px;
    }
    @media screen and (max-width: @breakpoint-sm) {
      height: 120px;
    }
    height: 200px;
  }
  &-rop {
    height: 100%;
    position: absolute;
    border-left: 4px solid @white;
    z-index: -1;
    animation: page-title-animation 1s;
    &-left {
      left: 44%;
    }
    &-center-left {
      left: 48%;
    }
    &-center-right {
      left: 52%;
    }
    &-right {
      left: 56%;
    }
  }
}
