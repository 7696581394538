.auth {
  height: 100vh;
  background-image: url('../../images/img/backgrounds/background.jpg');
  background-size: cover;
  &-wrapper {
    width: 500px;
    height: max-content;
    padding: 20px;
    border: 2px solid @grey;
    border-radius: @border-radius-lg;
    background: rgba(100%, 100%, 100%, 0.9);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
}
